import React from "react";

const CommunitySummary = ({
  currentUserName,
  currentUserEmail,
  ownerIdx,
  participants,
  physical,
  physicalDay1,
  physicalDay2,
  physicalDay3,
  child,
  childDay1,
  childDay2,
  childDay3,
}) => {
  return (
    <div id="communitySummary">
      <div className="summaryTable">
        <h4>Order Summary</h4>
        <table className="table">
          <tbody>
            <tr>
              <th>No.</th>
              <th>Name</th>
              <th>Email</th>
              <th>Coming As</th>
              <th>Ticket Type</th>
              <th>Ticket Price</th>
            </tr>
            {participants.map((guest) => {
              const selected = physical
                .concat(child)
                .find((pro) => guest.product_ids.includes(pro.id));
              const selectedDay1 = physicalDay1
                .concat(childDay1)
                .find((pro) => guest.product_ids.includes(pro.id));
              const selectedDay2 = physicalDay2
                .concat(childDay2)
                .find((pro) => guest.product_ids.includes(pro.id));
              const selectedDay3 = physicalDay3
                .concat(childDay3)
                .find((pro) => guest.product_ids.includes(pro.id));

              return (
                <>
                  {selected && (
                    <tr>
                      <td>{guest.id}</td>
                      <td>
                        {ownerIdx === guest.id
                          ? currentUserName
                          : guest.full_name}
                      </td>
                      <td>
                        {ownerIdx === guest.id ? currentUserEmail : guest.email}
                      </td>
                      <td>{guest.coming_as}</td>
                      <td>{selected.productName}</td>
                      <td>{selected.price}</td>
                    </tr>
                  )}
                  {selectedDay1 && (
                    <tr>
                      <td>{guest.id}</td>
                      <td>
                        {ownerIdx === guest.id
                          ? currentUserName
                          : guest.full_name}
                      </td>
                      <td>
                        {ownerIdx === guest.id ? currentUserEmail : guest.email}
                      </td>
                      <td>{guest.coming_as}</td>
                      <td>{selectedDay1.productName}</td>
                      <td>{selectedDay1.price}</td>
                    </tr>
                  )}
                  {selectedDay2 && (
                    <tr>
                      <td>{guest.id}</td>
                      <td>
                        {ownerIdx === guest.id
                          ? currentUserName
                          : guest.full_name}
                      </td>
                      <td>
                        {ownerIdx === guest.id ? currentUserEmail : guest.email}
                      </td>
                      <td>{guest.coming_as}</td>
                      <td>{selectedDay2.productName}</td>
                      <td>{selectedDay2.price}</td>
                    </tr>
                  )}
                  {selectedDay3 && (
                    <tr>
                      <td>{guest.id}</td>
                      <td>
                        {ownerIdx === guest.id
                          ? currentUserName
                          : guest.full_name}
                      </td>
                      <td>
                        {ownerIdx === guest.id ? currentUserEmail : guest.email}
                      </td>
                      <td>{guest.coming_as}</td>
                      <td>{selectedDay3.productName}</td>
                      <td>{selectedDay3.price}</td>
                    </tr>
                  )}
                </>
              );
            })}
            <tr>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th>Total (8% SST Inclusive)</th>
              <th>
                {physical.concat(child)[0].currency === "myr" ? "RM" : "EUR"}
                {participants
                  .map((per) => {
                    const amountSelected = physical
                      .concat(child)
                      .find((pro) => per.product_ids.includes(pro.id))
                      ? physical
                          .concat(child)
                          .find((pro) => per.product_ids.includes(pro.id))
                          .amount
                      : 0;

                    const amountSelectedDay1 = physicalDay1
                      .concat(childDay1)
                      .find((pro) => per.product_ids.includes(pro.id))
                      ? physicalDay1
                          .concat(childDay1)
                          .find((pro) => per.product_ids.includes(pro.id))
                          .amount
                      : 0;

                    const amountSelectedDay2 = physicalDay2
                      .concat(childDay2)
                      .find((pro) => per.product_ids.includes(pro.id))
                      ? physicalDay2
                          .concat(childDay2)
                          .find((pro) => per.product_ids.includes(pro.id))
                          .amount
                      : 0;

                    const amountSelectedDay3 = physicalDay3
                      .concat(childDay3)
                      .find((pro) => per.product_ids.includes(pro.id))
                      ? physicalDay3
                          .concat(childDay3)
                          .find((pro) => per.product_ids.includes(pro.id))
                          .amount
                      : 0;

                    return (
                      amountSelected +
                      amountSelectedDay1 +
                      amountSelectedDay2 +
                      amountSelectedDay3
                    );
                  })
                  .reduce(
                    (accumulator, currentValue) => accumulator + currentValue,
                    0
                  ) / 100}
              </th>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};
export default CommunitySummary;
