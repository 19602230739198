import React, { useState, useEffect } from "react";
import { CommunityCard } from "./CommunityCard";
import CommunitySummary from "./CommunitySummary";

const CommunityDetails = ({
  enableEdit,
  ownerCountry,
  currentUserName,
  currentUserEmail,
  token,
  url,
  physical,
  child,
  galaOnly,
  physicalDay1,
  physicalDay2,
  physicalDay3,
  malaysianOnly,
  childDay1,
  childDay2,
  childDay3,
  minPax,
  existingOrder,
  sessionData,
  paymentPath,
}) => {
  const [pax, setPax] = useState(
    enableEdit && sessionData.length > 0 ? sessionData.length : minPax
  ); // if account holder is less than 21yo, default set as 2
  const [participants, setParticipants] = useState(
    sessionData.length > 0
      ? sessionData.map((g) => ({
          id: g.id,
          title: g.title,
          full_name: g.fullName,
          email: g.email,
          phone: g.phone,
          country: g.country,
          ic_number: g.icNumber,
          passport: g.passport,
          meal_preference: g.mealPreference,
          meal_preference_other: g.mealPreferenceOther,
          coming_as: g.comingAs,
          other_coming_as: g.otherComingAs,
          gender: g.gender,
          languages: g.languages,
          equipment: g.equipment,
          product_ids: g.productIds,
        }))
      : []
  );
  const [owner, setOwner] = useState(1);
  const [validity, setValidity] = useState([]);
  const [showSummary, setShowSummary] = useState(existingOrder && !enableEdit);

  useEffect(() => {
    if (!(sessionData.length > 0)) {
      if (pax - participants.length === 1) {
        console.log("first user");
        setParticipants([
          ...participants,
          {
            id: pax,
            title: "",
            full_name: "",
            email: "",
            phone: "",
            country: ownerCountry,
            ic_number: "",
            passport: "",
            meal_preference: "",
            meal_preference_other: "",
            coming_as: "",
            other_coming_as: "",
            gender: "",
            languages: [],
            equipment: "",
            product_ids: [],
          },
        ]);
        setValidity([
          ...validity,
          {
            id: pax,
            valid: false,
          },
        ]);
      } else {
        const guests = Array.from({ length: pax }, (_, index) => {
          return {
            id: index + 1,
            title: "",
            full_name: "",
            email: "",
            phone: "",
            country: ownerCountry,
            ic_number: "",
            passport: "",
            meal_preference: "",
            meal_preference_other: "",
            coming_as: "",
            other_coming_as: "",
            gender: "",
            languages: [],
            equipment: "",
            product_ids: [],
          };
        });
        setParticipants(guests);
        setValidity(
          Array.from({ length: pax }, (_, index) => {
            return {
              id: index + 1,
              valid: false,
            };
          })
        );
      }
    } else {
      // if existing order mean all fields supposed to be valid
      setValidity(
        participants.map((p, index) => {
          return {
            id: index + 1,
            valid: true,
          };
        })
      );
    }
  }, [pax]);

  const decrementCount = (num) => {
    if (num >= minPax) {
      setPax(num);
    }
  };
  const disableBtn = !(
    owner !== null &&
    validity.map((v) => v.valid).every((val) => val === true) &&
    participants.filter((per) => per.coming_as === "Child SBH (3 - 20 y/o)")
      .length <= 1
  );

  // check email whether already taken, show error message
  // must fill in myself as participant 1 (compulsory to tick the checkbox)
  // only allow to have 1 child sbh in one group registration
  // if owner is child then must accompany by adult
  return (
    <div id="communityDetails">
      {!showSummary && (
        <>
          <div className="text-center">
            <h6>How many pax are you registering for?</h6>
            <div className="paxCount d-flex align-items-center justify-content-center">
              <button
                className={pax === minPax ? "btn-disabled" : "available"}
                onClick={() => decrementCount(pax - 1)}
              >
                -
              </button>
              <i className="fa-regular fa-user me-3"></i>
              <span>{pax}</span>
              <button onClick={() => setPax(pax + 1)}>+</button>
            </div>
          </div>
          {minPax > 1 && (
            <p className="text-center text-danger errorMessage">
              * Participants that are younger than 21 years old MUST be
              accompanied by an adult
            </p>
          )}
        </>
      )}
      <form action={url} method="POST">
        <input type="hidden" name="authenticity_token" value={token} />
        <input
          type="hidden"
          name="guest_data"
          value={JSON.stringify(participants)}
        />
        <input type="hidden" name="number_of_guests" value={pax} />
        <input type="hidden" name="owner_index" value={owner || ""} />
        {!showSummary ? (
          <>
            <div className="row">
              {Array.from({ length: pax }, (_, index) => index + 1).map(
                (card, idx) => (
                  <div key={card} className="col-md-6 px-2 my-5">
                    <p className="guest-num text-center">Participant {card}</p>
                    <CommunityCard
                      index={card}
                      owner={owner}
                      setOwner={setOwner}
                      guest={participants.find((guest) => guest.id === card)}
                      participants={participants}
                      setParticipants={setParticipants}
                      validity={validity}
                      setValidity={setValidity}
                      physical={physical}
                      child={child}
                      minPax={minPax}
                      physicalDay1={physicalDay1}
                      physicalDay2={physicalDay2}
                      physicalDay3={physicalDay3}
                      malaysianOnly={malaysianOnly}
                      childDay1={childDay1}
                      childDay2={childDay2}
                      childDay3={childDay3}
                    />
                  </div>
                )
              )}
            </div>
            {participants.filter(
              (per) => per.coming_as === "Child SBH (3 - 20 y/o)"
            ).length > 1 && (
              <p className="text-center text-danger errorMessage">
                * Limited to only 1 Child SBH per group registration
              </p>
            )}
            <button
              className="theme-btn d-block mx-auto mt-4"
              onClick={() => setShowSummary(true)}
              disabled={disableBtn}
            >
              Next
            </button>
          </>
        ) : (
          <>
            <CommunitySummary
              ownerIdx={owner}
              participants={participants}
              child={child}
              childDay1={childDay1}
              childDay2={childDay2}
              childDay3={childDay3}
              physical={physical}
              physicalDay1={physicalDay1}
              physicalDay2={physicalDay2}
              physicalDay3={physicalDay3}
              currentUserName={currentUserName}
              currentUserEmail={currentUserEmail}
            />
            {/* if is existing order => button proceed to choose payment method instead of create new order  */}
            {existingOrder ? (
              <>
                {enableEdit ? (
                  <button
                    type="submit"
                    className="theme-btn d-block mx-auto mt-4"
                    disabled={disableBtn}
                  >
                    Purchase Pass
                  </button>
                ) : (
                  <a
                    href={paymentPath}
                    className="theme-btn d-inline-block mx-auto mt-4"
                  >
                    Make Payment
                  </a>
                )}
              </>
            ) : (
              <button
                type="submit"
                className="theme-btn d-block mx-auto mt-4"
                disabled={disableBtn}
              >
                Purchase Pass
              </button>
            )}
          </>
        )}
      </form>
    </div>
  );
};
export default CommunityDetails;
